import { useEffect } from 'react';
import { analyticsInstance } from '../firebase/firebase-config';
import { logEvent } from "firebase/analytics";

const useFirebaseAnalytics = (pageTitle) => {
    useEffect(() => {
        const eventDetails = {
            page_title: pageTitle || 'Default Title',
            page_location: window.location.href,
            page_path: window.location.pathname
        };

        console.log('Page title:', pageTitle);
        console.log('Event details:', eventDetails);
        if (analyticsInstance) {
            logEvent(analyticsInstance, 'page_view', eventDetails);
            console.log('Firebase Analytics event logged');
        } else {
            console.log('Analytics is not initialized');
        }
    }, [pageTitle]);
};

export default useFirebaseAnalytics;