import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAmh_IePOeNQmL8pUuR2LKGE5Oyw1twfWQ",
    authDomain: "perfectraveller-a3a0f.firebaseapp.com",
    projectId: "perfectraveller-a3a0f",
    storageBucket: "perfectraveller-a3a0f.firebasestorage.app",
    messagingSenderId: "356210191586",
    appId: "1:356210191586:web:97b12fac37a3b40ebf82bb",
    measurementId: "G-VXGRZYWLJS"
};

const app = initializeApp(firebaseConfig);
let analyticsInstance;

isSupported().then(supported => {
    if (supported) {
        analyticsInstance = getAnalytics(app);
        console.log('Firebase Analytics has been enabled:', analyticsInstance);
    } else {
        console.log("Firebase Analytics not supported in this environment");
    }
}).catch(error => console.error("Error initializing Firebase Analytics", error));

export { app, analyticsInstance };
